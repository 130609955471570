import moment from "moment";

const initialState = {
  token: localStorage.getItem("principal_token"),
  isAuthenticated: null,
  isLoading: true,
  user: null,
  isLoadingMessages: true,
  isSocketLoading: true,
  loginFailed: false,
  notifications: [],
  unseenNotifications: [],
  isLoadingNotifications: true,
  csrfToken: null,
  entryPage: "/",
  alert: null,
  expiry: null,
  tier: null,
  initiated: "PENDING",
  finalized: false,
  institutionName: "",
};

export default function (state = initialState, action) {
  console.log("-------------");
  console.log(action);
  console.log("-------------");

  switch (action.type) {
    case "USER_LOADING":
      return {
        ...state,
        token: localStorage.getItem("principal_token"),
      };
    case "USER_LOADED":
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload,
      };
    case "TOKEN_LOADED":
      return {
        ...state,
        csrfToken: action.payload,
      };
    case "CONTACTS_LOADED":
      return {
        ...state,
        contacts: action.payload,
      };
    case "MESSAGES_LOADED":
      return {
        ...state,
        messages: action.payload,
        isLoadingMessages: false,
      };
    case "SOCKET_LOADED":
      return {
        ...state,
        client: action.payload,
        isSocketLoading: false,
      };
    case "TEXT_MESSAGE":
      return {
        ...state,
        message: action.payload,
      };
    case "LOGIN_SUCCESS":
      localStorage.setItem("principal_token", "Token " + action.payload.token);
      return {
        ...state,
        token: "Token " + action.payload.token,
        loginFailed: false,
      };
    case "LOGOUT_SUCCESS":
      localStorage.removeItem("principal_token");
      return {
        ...state,
        token: null,
        user: null,
        isAuthenticated: false,
        isLoadingMessages: true,
        isSocketLoading: true,
        isLoading: true,
        messages: null,
        message: null,
        loginFailed: true,
        csrfToken: null,
      };
    case "PASSWORD_RESET":
      localStorage.removeItem("principal_token");
      return {
        ...state,
        token: null,
        user: null,
        isAuthenticated: false,
        isLoadingMessages: true,
        isSocketLoading: true,
        isLoading: true,
        messages: null,
        message: null,
        loginFailed: true,
        csrfToken: null,
      };
    case "SUBSCRIPTION":
      return {
        ...state,
        isLoading: false,
        expiry: moment(action.payload.expiry, "DD-MM-YYYY").toDate(),
        tier: action.payload.tier,
      };
    case "AUTH_ERROR":
      localStorage.removeItem("principal_token");
      return {
        ...state,
        token: null,
        user: null,
        isAuthenticated: false,
        isLoadingMessages: true,
        isSocketLoading: true,
        isLoading: true,
        messages: null,
        message: null,
        loginFailed: true,
        csrfToken: null,
      };
    case "RESET_REDIRECT_LOGIN":
      return {
        ...state,
        loginFailed: false,
      };
    case "ALERT":
      return {
        ...state,
        alert: {
          title: action.payload.title,
          message: action.payload.message,
          type: action.payload.type,
        },
      };
    case "REMOVE_ALERT":
      return {
        ...state,
        alert: null,
      };
    case "CURRENT_YEAR":
      return {
        ...state,
        current_year: action.payload,
      };
    case "SETUP_STATUS":
      return {
        ...state,
        initiated: action.payload["initiated"],
        finalized: action.payload["finalized"],
        classType: action.payload["class_type"],
        institutionName: action.payload["institution_name"],
      };
    case "NOTIFICATIONS_LOADED":
      return {
        ...state,
        notifications: action.payload,
        isLoadingNotifications: false,
      };
    case "UNSEEN_NOTIFICATIONS_LOADED":
      return {
        ...state,
        unseenNotifications: action.payload,
      };
    case "ENTRY_PAGE":
      return {
        ...state,
        entryPage: action.payload,
      };
    case "REFRESH_DATA":
      return {
        ...state,
        refresh: action.payload,
      };
    default:
      return state;
  }
}
